<template>
   <div v-if="projet != false">
      <b-card class="card-custom mt-3 card-titre sticky-top" no-body style="z-index: 25;">
        <template #header>
           <div class="card-title">
            <b-button v-b-tooltip.hover title="Retour au devis" variant="light-danger" :href="`/#/devis/detail/${projet.id}`" class="btn-icon"><i class="fa fa-chevron-left"></i></b-button>
            <h3 class="card-label p-3 bg-success text-white rounded ml-2">Contrat <strong>#{{projet.id}} - {{projet.nom}} {{ projet.prenom }}</strong></h3>
           </div>
           <div class="card-title">  
            <b-button v-if="file != false" v-b-tooltip.hover title="Contrat" variant="light-info" class="btn-icon mr-1" :href="`${$api.url.api}/file/pdf/${file.uuid}`" target="_blank" size="md">
              <i class="fas fa-file-pdf fa-size"></i>              
            </b-button>
            <Mail v-if="file != false" :client_id="projet.client_id" :link="`${$api.url.app}/#/document/f/${file.uuid}`"  :obj="projet" type="Contrat" class="mr-1" />        
            <b-button v-b-tooltip.hover title="Sauvegarder" variant="success" class="btn-icon" @click="save()" size="md">
              <i v-if="save_loader === false" class="fas fa-save fa-size"></i>
              <i v-else class="fas fa-spinner fa-spin fa-size"></i>
            </b-button>
        </div>
        </template>
     </b-card>
     <form ref="form" class="mt-3">
      <b-card class="card-search mt-3">
         <b-card-text class="font-weight-bold h5">Étude personnalisée</b-card-text>
         <Etude :projet="projet" />
      </b-card>
      <b-card class="card-custom mt-3">
         <b-row>
            <b-col md="6">
               <b-card-text class="font-weight-bold h5">Votre offre</b-card-text>
            </b-col>
            <b-col md="6" class="text-right">
               <b-button variant="primary" class="mr-2" @click="openResiliation('lettre de rétractation', 43, 'LettreRetract')" v-b-tooltip.hover title="Lettre de rétractation">LR</b-button>
               <b-button variant="light-danger" class="btn-icon" @click="openResiliation('lettre de résiliation', 42, 'LettreResil')" v-b-tooltip.hover title="Lettre de resiliation individuelle"><i class="fas fa-envelope-open"></i></b-button>
            </b-col>
         </b-row>                  
         <b-row>
            <b-col md="4">
               <b-form-group label="Mutuelle">
                  <b-form-input :value="$api.p('Mutuelle', quote.mutuelle_id).titre" disabled></b-form-input>
               </b-form-group>
            </b-col>
            <b-col md="5">
               <b-form-group label="Formue">
                  <b-form-input :value="quote.titre" disabled></b-form-input>
               </b-form-group>
            </b-col>
            <b-col md="3">
               <b-form-group label="Cotisation mensuelle">
                  <b-input-group>
                     <b-form-input :value="$api.price(quote.tarif)" disabled></b-form-input>
                     <!-- <b-input-group-append>
                        <b-button variant="light-warning" @click="remise(5)">- 5%</b-button>
                        <b-button variant="light-danger" @click="remise(10)">- 10%</b-button>
                     </b-input-group-append> -->
                  </b-input-group>
               </b-form-group>
            </b-col>
         </b-row>
      </b-card>
      <AncienContrat :obj="projet" />
      <b-card class="card-search mt-3" v-if="etudeOnly === false">
         <b-card-text class="font-weight-bold h5">Information generale</b-card-text>
         <b-row>
            <b-col md="4">
               <b-form-group label="Nouvelle adhesion">
                  <b-form-checkbox v-model="projet.autre.nouvelle_adhesion" size="lg"></b-form-checkbox>
               </b-form-group>
            </b-col>
            <b-col md="4" v-if="projet.autre.nouvelle_adhesion == false">
               <b-form-group label="Numero d'adheran">
                  <Input type="text" v-model="projet.autre.numero_adhesion" :required="true" />
               </b-form-group>
            </b-col>
            <b-col md="4">
               <b-form-group label="Situation matrimoniale:">
                  <select class="form-control" v-model="projet.autre.situation_matrimoniale" required>
                     <option v-for="(e, i) in bia.situation_familiale" :key=i :value="e">{{ e }}</option>                     
                  </select>
               </b-form-group>
            </b-col>
         </b-row>
         <b-row>
            <b-col md="6">
               <b-form-group label="Nom de naissance:">
                  <Input type="text" v-model="projet.nom_naissance"/>                  
               </b-form-group>
            </b-col>
            <b-col md="6">
               <b-form-group label="Telephone 1:">
                  <Telephone v-model="projet.telephone_1" :edit="true" />
               </b-form-group>
            </b-col>
            <b-col md="6">
               <b-form-group label="Telephone 2:">
                  <Telephone v-model="projet.telephone_2" :edit="true" />
               </b-form-group>
            </b-col>
            <FieldForm model="Projet" :obj="projet" field="email" />
            <FieldForm model="Projet" :obj="projet" field="adresse" />
            <FieldForm model="Projet" :obj="projet" field="adresse_2" />
            <FieldForm model="Projet" :obj="projet" field="cp" />
            <FieldForm model="Projet" :obj="projet" field="ville" />
            <b-col md="6">
               <b-form-group label="Date d'éffet:">
                  <input type="date" class="form-control" required v-model="projet.date_effet" :min="$api.moment().format('YYYY-MM-DD')" :max="max_d_effet">
               </b-form-group>
            </b-col>               
         </b-row>
         <b-row v-if="bia.other != undefined">               
            <b-col md="4" v-for="(v,k) in bia.other" :key="k">
               <b-form-group :label="k">
                  <input v-if="v === null" type="text" class="form-control" required v-model="projet.other[k]" />
                  <select v-else class="form-control" v-model="projet.other[k]" required>
                     <option v-for="(tmp, ti) in v" :key="ti" :value="tmp">{{ tmp }}</option>
                  </select>
               </b-form-group>               
            </b-col>
         </b-row>            
      </b-card>
      <b-card class="card-search mt-3" v-if="etudeOnly === false">
         <b-card-text class="font-weight-bold h5">Liste des assurés</b-card-text>   
         <b-row>
            <b-col md="2">
               <b-form-group label="Loi Madelin">
                  <select v-model="projet.loi_madelin" class="form-control">
                     <option :value="true">Oui</option>
                     <option :value="false">Non</option>
                  </select>
               </b-form-group>      
            </b-col>
         </b-row>
         <b-table-simple hover striped responsive>
            <b-thead head-variant="light">
               <b-tr>
                  <b-th>Assuré</b-th>
                  <b-th>Civilite</b-th>
                  <b-th>Nom</b-th>
                  <b-th>Nom de naissance</b-th>
                  <b-th>Prenom</b-th>
                  <b-th>Date de naissance</b-th>
                  <b-th>Regime</b-th>
                  <b-th>Numero secu</b-th>
                  <b-th>Num. Organisme d'affiliation</b-th>                    
                  <b-th v-if="statutExiste">Statut</b-th>
                  <b-th v-if="categorie_sociale">Categorie sociale</b-th>
                  <b-th v-if="noemie">Noemie</b-th>
               </b-tr>
            </b-thead>
            <b-tbody>
               <b-tr>
                  <b-td>Principal</b-td>
                  <b-td>
                     <select v-model="projet.civilite" class="form-control" required>
                        <option value="Mr">Mr</option>
                        <option value="Mme">Mme</option>
                     </select>
                  </b-td>
                  <b-td><Input type="text" v-model="projet.nom" required /></b-td>
                  <b-td><Input type="text" v-model="projet.nom_naissance"/></b-td>
                  <b-td><Input type="text" v-model="projet.prenom" required /></b-td>
                  <b-td><Input type="date" v-model="projet.date_naissance" required/></b-td>
                  <b-td>
                     <select v-model="projet.regime_id" class="form-control" required>
                        <option v-for="(r, i) in $api.params.Regime" :key="i" :value="r.id">{{ r.titre }}</option>
                     </select>
                  </b-td>
                  <b-td>                     
                     <b-form-input type="text" v-model="projet.numero_secu" required minlength="15" maxlength="15"></b-form-input>
                  </b-td>
                  <b-td>                     
                     <b-form-input type="text" v-model="projet.num_organisme_affiliation" required minlength="9" maxlength="9"></b-form-input>
                  </b-td>
                  <b-td v-if="statutExiste">
                     <select class="form-control" v-model="projet['p_statut']" required>
                        <option v-for="(s, i) in bia.statut" :key="i" :value="s">{{ s }}</option>
                     </select>
                  </b-td>
                  <b-td v-if="categorie_sociale">
                     <b-form-input type="text" v-model="projet.p_categorie_sociale" required></b-form-input>
                  </b-td>
                  <b-td v-if="noemie">
                     <b-form-radio v-model="projet.p_noemie" name="p_noemie" :value="true" required>Oui</b-form-radio>
                     <b-form-radio v-model="projet.p_noemie" name="p_noemie" :value="false" required>Non</b-form-radio>
                  </b-td>
               </b-tr>
               <b-tr v-if="projet.conjoint.regime_id != null">
                  <b-td>Conjoint</b-td>
                  <b-td>
                     <select v-model="projet.conjoint.civilite" class="form-control" required>
                        <option value="Mr">Mr</option>
                        <option value="Mme">Mme</option>
                     </select>
                  </b-td>
                  <b-td><Input type="text" v-model="projet.conjoint.nom" required /></b-td>
                  <b-td><Input type="text" v-model="projet.conjoint.nom_naissance"/></b-td>
                  <b-td><Input type="text" v-model="projet.conjoint.prenom" required/></b-td>
                  <b-td><Input type="date" v-model="projet.conjoint.date_naissance" :required="projet.conjoint.regime_id != null" /></b-td>
                  <b-td>
                     <select v-model="projet.conjoint.regime_id" class="form-control" required>
                        <option :value="null">Aucun</option>
                        <option v-for="(r, i) in $api.params.Regime" :key="i" :value="r.id">{{ r.titre }}</option>
                     </select>
                  </b-td>
                  <b-td>                     
                     <b-form-input type="text" v-model="projet.conjoint.numero_secu" required minlength="15" maxlength="15"></b-form-input>
                  </b-td>
                  <b-td>                     
                     <b-form-input type="text" v-model="projet.conjoint.num_organisme_affiliation" required minlength="9" maxlength="9"></b-form-input>
                  </b-td>
                  <b-td v-if="statutExiste">                     
                  </b-td>
                  <b-td v-if="categorie_sociale">
                     <b-form-input type="text" v-model="projet.c_categorie_sociale" required></b-form-input>
                  </b-td>
                  <b-td v-if="noemie">
                     <b-form-radio v-model="projet.c_noemie" name="c_noemie" :value="true" required>Oui</b-form-radio>
                     <b-form-radio v-model="projet.c_noemie" name="c_noemie" :value="false" required>Non</b-form-radio>
                  </b-td>
               </b-tr>
               <b-tr v-for="(e, i) in projet.enfants" :key="i">
                  <b-td>Enfant {{  i + 1 }}</b-td>
                  <b-td>
                     <select v-model="e.civilite" class="form-control" required>
                        <option value="Mr">Mr</option>
                        <option value="Mme">Mme</option>
                     </select>
                  </b-td>
                  <b-td><Input type="text" v-model="e.nom" required/></b-td>
                  <b-td></b-td>
                  <b-td><Input type="text" v-model="e.prenom" required /></b-td>
                  <b-td><Input type="date" v-model="e.date_naissance" required/></b-td>
                  <b-td>
                     <select v-model="e.regime_id" class="form-control" required>
                        <option :value="null">Aucun</option>
                        <option v-for="(r, i) in $api.params.Regime" :key="i" :value="r.id">{{ r.titre }}</option>
                     </select>
                  </b-td>
                  <b-td>     
                     <b-form-input type="text" v-model="e.numero_secu" required minlength="15" maxlength="15"></b-form-input>                                                      
                  </b-td>
                  <b-td>
                     <b-form-input type="text" v-model="e.num_organisme_affiliation" required minlength="9" maxlength="9"></b-form-input>
                  </b-td>
                  <b-td v-if="statutExiste">                     
                  </b-td>
                  <b-td v-if="categorie_sociale">
                     <b-form-input type="text" v-model="e.categorie_sociale" required></b-form-input>
                  </b-td>
                  <b-td v-if="noemie">
                     <b-form-radio v-model="e.noemie" :name="`e_${i}_noemie`" :value="true" required>Oui</b-form-radio>
                     <b-form-radio v-model="e.noemie" :name="`e_${i}_noemie`" :value="false" required>Non</b-form-radio>
                  </b-td>
               </b-tr>
            </b-tbody>
         </b-table-simple>
      </b-card>
      <b-card class="card-custom mt-3" v-if="etudeOnly === false">
         <b-card-text class="font-weight-bold h5">Paiement</b-card-text>
         <b-row>
            <b-col md="4">
               <b-form-group label="Nom du debiteur">
                  <Input v-model="projet.paiement.nom" type="text" required/>
               </b-form-group>
            </b-col>
            <b-col md="4">
               <b-form-group label="Prenom du debiteur">
                  <Input v-model="projet.paiement.prenom" type="text" required/>
               </b-form-group>
            </b-col>
            <!-- <b-col md="4">
               <b-form-group label="Nom prenom du debiteur">
                  <Input v-model="projet.paiement.nom_prenom_debiteur" type="text" required/>
               </b-form-group>
            </b-col> -->
            <b-col md="4">
               <b-form-group label="Adresse du debiteur">
                  <Input v-model="projet.paiement.adresse" type="text" required/>
               </b-form-group>
            </b-col>
            <b-col md="4">
               <b-form-group label="Ville du debiteur">
                  <Input v-model="projet.paiement.ville" type="text" required/>
               </b-form-group>
            </b-col>
            <b-col md="4">
               <b-form-group label="CP du debiteur">
                  <Input v-model="projet.paiement.cp" type="text" required/>
               </b-form-group>
            </b-col>
            <b-col md="4">
               <b-form-group label="Pays du debiteur">
                  <Input v-model="projet.paiement.pays" type="text" required/>
               </b-form-group>
            </b-col>
            <b-col md="4">
               <b-form-group label="Periode">
                  <select v-model="projet.paiement.periode" class="form-control" required>
                     <option value="Mensuelle">Mensuelle</option>
                     <option value="Trimestrielle">Trimestrielle</option>
                     <option value="Semestrielle">Semestrielle</option>
                     <option value="Annuelle">Annuelle</option>
                  </select>
               </b-form-group>
            </b-col>
            <b-col md="4">
               <b-form-group label="Jour du prelevement">
                  <select v-model="projet.paiement.periode_jour" class="form-control" required>
                     <option v-for="(j, i) in bia.jour_prelevement" :key="i" :value="j">{{ j }}</option>
                  </select>                  
               </b-form-group>
            </b-col>
            <b-col md="4">
               <b-form-group label="IBAN">
                  <b-form-input type="text" v-model="projet.paiement.iban" required  maxlength="27"></b-form-input>                                                                          
               </b-form-group>
            </b-col>
            <b-col md="4">
               <b-form-group label="BIC">
                  <b-form-input type="text" v-model="projet.paiement.bic" required  maxlength="11"></b-form-input>                                                                                              
               </b-form-group>
            </b-col>
         </b-row>
      </b-card>
   </form>
   <b-modal ref="resiliation" id="resiliation" hide-footer :title="tmpFile.titre" size="xl">      
      <b-form ref="lettre-form" v-if="tmpResiliation != false">
         <b-card class="card-custom mt-3">          
          <b-row>
            <b-col md="4">
              <b-form-group label="Nom">
                <Input v-model="tmpResiliation.nom" :required="true" type="text"/>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Prenom">
                <Input v-model="tmpResiliation.prenom" :required="true" type="text"/>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Telephone">
                <Input v-model="tmpResiliation.telephone_1" :required="true" type="text"/>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Email">
                <Input v-model="tmpResiliation.email" :required="true" type="text"/>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Adresse">
                <Input v-model="tmpResiliation.adresse" :required="true" type="text"/>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Ville">
                <Input v-model="tmpResiliation.ville" :required="true" type="text"/>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="CP">
                <Input v-model="tmpResiliation.cp" :required="true" type="text"/>
              </b-form-group>
            </b-col>                                
            <b-col md="4">
              <b-form-group label="Date effet">
                <Input v-model="tmpResiliation.date_effet" :required="true" type="date"/>
              </b-form-group>
            </b-col>                         
            <b-col md="4"  v-if="tmpFile.tpl_id === 43">
              <b-form-group label="Date signature">
                <Input v-model="tmpResiliation.date_signature" :required="true" type="date"/>
              </b-form-group>
            </b-col>                         
            <b-col md="4" v-if="tmpFile.tpl_id === 42">
              <b-form-group label="Date de naissance">
                <Input v-model="tmpResiliation.date_naissance" :required="true" type="date"/>
              </b-form-group>
            </b-col>                         
            <b-col md="4">
              <b-form-group label="Numero de secu">
                  <select class="form-control" v-model="tmpResiliation.numero_secu">
                     <option :value="projet.numero_secu">{{ projet.numero_secu }} (Principal)</option>
                     <option :value="projet.conjoint.numero_secu" v-if="projet.conjoint.numero_secu != null">{{ projet.conjoint.numero_secu }} (Conjoint)</option>
                  </select>                
              </b-form-group>
            </b-col>                                       
          </b-row>
        </b-card>
        <AncienContrat :obj="tmpResiliation" :motif="tmpFile.tpl_id != 43"/>
        <b-row class="mt-3">
          <b-col md="12" class="text-center">
            <b-button v-if="resiliation_link != false" v-b-tooltip.hover title="Lettre" variant="light-info" class="btn-icon mr-1" :href="`${$api.url.app}/#/document/f/${resiliation_link}`" target="_blank" size="md">
              <i class="fas fa-file-pdf fa-size"></i>              
            </b-button>
            <Mail v-if="resiliation_link != false" :client_id="projet.client_id" :link="`${$api.url.app}/#/document/f/${resiliation_link}`" :obj="tmpResiliation" :type="tmpFile.mail" class="mr-1 d-inline" />
            <b-button variant="primary">
              <span v-if="load_resiliation === false" @click="generateResiliation">Generer</span>
              <i v-else class="fas fa-spin fa-spinner"></i>              
            </b-button>            
          </b-col>
         </b-row>      
      </b-form>
   </b-modal>
</div>
</template>
<script>
import FieldForm from '@/components/FieldForm';
import Telephone from '@/components/Telephone';
import Input from '@/components/InputForm';
import Mail from '@/components/Mail';
import Etude from '@/components/Etude';
import AncienContrat from '@/components/AncienContrat';
export default {
  name: "Contrat",
  components: {    
    FieldForm, Telephone, Input, Mail, AncienContrat, Etude
  },
  data(){
    return{
      tabIndex:0,   
      save_loader: false,   
      quote: false,
      projet: false,
      bia: false,           
      file:false,
      tmpResiliation: false,
      tmpFile:{
         "titre":null,
         "tpl_id":null,
         "mail":null
      },
      load_resiliation: false,
      resiliation_link: false
    }
  },
  watch:{   
   'projet.adresse'(){
      var adresse = this.projet.adresse;
      if(this.projet.adresse_2 != null && this.projet.adresse_2 != ""){
         adresse += " - "+this.projet.adresse_2;
      }
      this.projet.paiement.adresse = adresse;
   },
   'projet.adresse_2'(){
      var adresse = this.projet.adresse;
      if(this.projet.adresse_2 != null && this.projet.adresse_2 != ""){
         adresse += " - "+this.projet.adresse_2;
      }
      this.projet.paiement.adresse = adresse;
   }
  },
  computed:{    
   statutExiste(){
      if(this.bia.statut != undefined && this.bia.statut.length > 0){
         return true;
      }else{
         return false;
      }
   },
   etudeOnly(){
      return this.bia.etude_only;
   },
   categorie_sociale(){
      return this.bia.categorie_sociale === true;
   },
   noemie(){
      return this.bia.noemie === true;      
   },
   max_d_effet(){
      var next =  this.$api.moment().add(1, 'Y');
      return next.format('YYYY-MM-DD');
   }
  },
  beforeMount() {
  },
  mounted() {
    var route = this.$route;
    if(route.name.indexOf("Nouveau") > -1){      
      var query = "/"+parseInt(route.params.id)+"?index="+parseInt(route.query.index);            
      this.$api.ajax('/contrat/new'+query, null, res => {
        if(res.status){          
          this.projet = res.data.projet;         
          this.quote = res.data.quote;           
          this.bia = res.data.bia;            
        }
      })
    }else{
      this.$api.ajax('/projet/'+this.$route.params.id, null, res => {
        if(res.status){          
          this.projet = res.data.projet;
          this.garanties = res.data.garanties;
          this.quotes = res.data.quotes;
          this.files = res.data.files;          
          this.meta = res.data.meta;          
        }
      });
    }
  },
  methods: {
    checkForm(){
      var form = this.$refs.form;
      var fields = form.querySelectorAll("[required='required']");
      var valide = true;
      fields.forEach((item) => {
        if(item.checkValidity() === false){
          item.classList.add("need-validation");
          valide = false;
        }else{          
          item.classList.remove("need-validation");
        };
      });
      if(valide === false){
        this.$bvToast.toast('Merci de remplir tout les champs en rouge', {
          title: 'Champs',
          variant: 'warning',
          solid: true
        });
        this.tabIndex = 0;
        return false;
      }      
    },     
    save(){      
      if(this.save_loader === true){
        return false;
      }
      if(this.checkForm() === false){ 
         this.save_loader = true;        
         this.$api.ajax('/projet/create', this.projet, () => {
            this.$bvToast.toast('Sauvegarger, mais non geenrer', {
               title: 'Sauvegarder',
               variant: 'info',
               solid: true
            });
            this.save_loader = false;         
        });        
        return false;
      }
      var meilleurs = ['meilleur_prix', 'meilleur_rapport_prix', 'meilleure_couverture'];
      var count = 0;
      meilleurs.forEach(e => {
        if(this.projet.etude[e] === 'Oui'){
          count++;
        }
      });
      if(count != 1){
        this.$bvToast.toast('Merci de repondre Oui a une seule option', {
          title: 'Prix ou Rapport Garanties/Prix ou Couverture',
          variant: 'warning',
          solid: true
        });
        return false
      }
      this.save_loader = true;
      var params = {
        projet: this.projet,
        quote: this.quote
      }
      this.$api.ajax('/contrat/create', params, res => {
        if(res.status === true){
          this.file = res.data
        }
        this.save_loader = false;
      })
    },
    remise(pourent){
      if(this.quote.prix_base != this.quote.tarif){
         this.$bvToast.toast('Remise deja appliqué', {
            title: 'Attention',
            variant: 'warning',
            solid: true
         });
         return false;
      }
      this.quote.tarif = this.quote.tarif * ((100-pourent) / 100);
    },
    openResiliation(titre,tpl_id, mail){
      this.tmpFile = {
         titre: titre,
         tpl_id:tpl_id,
         mail: mail
      };
      if(this.tmpResiliation === false){
         var p = JSON.parse(JSON.stringify(this.projet));
         this.tmpResiliation = {
            id: p.id,
            nom: p.nom,
            prenom: null,
            adresse: p.adresse,
            adresse_2: p.adresse_2,
            telephone_1: p.telephone_1,
            email: p.email,
            cp: p.cp,
            ville: p.ville,
            date_effet: tpl_id === 43 ? null : p.date_effet,
            ancien:{
               raison_sociale:null,
               adresse:null,
               cp:null,
               ville:null,
               date_fin_contrat: null,
               numero_adherent:null,
               nouvelle_mutuelle: null,
               type_id: null
            },
            numero_secu:null
         }
      }
      this.$refs.resiliation.show();
    },
    generateResiliation(){
      var form = this.$refs['lettre-form'];
      var fields = form.querySelectorAll("[required='required']");
      var valide = true;
      fields.forEach((item) => {
        if(item.checkValidity() === false){
          item.classList.add("need-validation");
          valide = false;
        }else{          
          item.classList.remove("need-validation");
        };
      });
      if(valide === false){
        this.$bvToast.toast('Merci de remplir tout les champs en rouge', {
          title: 'Champs',
          variant: 'warning',
          solid: true
        });        
        return false;
      }
      if(this.load_resiliation === true){
        return false;
      }
      this.load_resiliation = true;
      var params = {
        projet: this.tmpResiliation,
        user_id: this.projet.user_id,
        tpl_id: this.tmpFile.tpl_id,
        titre: this.tmpFile.titre
      }
      this.$api.ajax('/projet/resiliation', params, res => {
        if(res.status === true){
          this.resiliation_link = res.data;
        }
        this.load_resiliation = false;
      })
    }
  }
}
</script>
<style scoped>
.garanties-block{
  table-layout: fixed;
  width: 100%;
}
.garanties-block input, .garanties-block textarea {
  display: inline;
  font-family: inherit;
  border: none;
  padding: 0 10px;
  margin: 0;
  width: 100%;
  background: none;
  text-align: center;
  font-size: 0.95rem;
}
</style>
<style>

#main-tabs__BV_tab_controls_ > .nav-item .nav-link.active{
  color: white !important;
}
#main-tabs__BV_tab_container_ > .tab-pane.card-body{
  padding: 0px;
  margin-top: 10px;
}
#main-tabs > .card-header:first-of-type {
  padding: 1rem 1.25rem !important;
}
.liste-custom {
  position: absolute;
  z-index: 9;
  width: 92%;
}
.select-pays select {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.d-contents {
  display: contents;
}
.need-validation {
  border: solid 2px red;
}
.input-budget {
  display: inline-block;
  max-width: 80px;
}
.custom-radio:has(> .need-validation) { 
  border-bottom: 1.5px solid red;
  padding-bottom: 10px;
 }
</style>
