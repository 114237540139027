<template>
    <router-view />
  </template>  
  <script>
  export default {
    data() {
      return {
        agent: {
          nom: 'Dupont',
          prenom: 'Jean',
          email: 'jean.dupont@company.fr',
          telephone: '06 12 34 56 78',
          whatsapp: '33612345678'
        }
      }
    }
  }
  </script>  