<template>
  <div v-if="client != false">
     <nav class="navbar">
        <div class="navbar__logo"> <img :src="$api.ste.logo" alt="Logo" class="logo"> </div>
        <div class="navbar__agent">
           <div class="agent-info">
              <h3>{{ agent.nom }} {{ agent.prenom }}</h3>
              <div class="agent-contact"> 
                <a :href="`mailto:${agent.email}`" class="contact-link"> <i class="fas fa-envelope"></i> {{ agent.email }} </a> 
                <a :href="`tel:${agent.telephone}`" class="contact-link"> <i class="fas fa-phone"></i> {{ agent.telephone }} </a> 
                <a :href="`https://wa.me/${agent.whatsapp}`" target="_blank" class="contact-link whatsapp"> <i class="fab fa-whatsapp"></i> WhatsApp </a> 
              </div>
           </div>
        </div>
     </nav>
     <div class="app-container">
        <div class="info-summary">
           <div class="info-header">
              <h2>Récapitulatif de votre situation</h2>
              <button @click="showEditPanel = true" class="btn-edit"> <i class="fas fa-edit"></i> Modifier </button> 
           </div>
           <div class="info-grid">
              <div class="info-section">
                 <h3>Assuré principal</h3>
                 <div class="info-details">
                    <p><strong>{{ client.civilite }}. {{ client.nom }}</strong></p>
                    <p>Né(e) le {{ formatDate(client.date_naissance) }}</p>
                    <p>Régime {{ titre('Regime', client.regime_id) }}</p>
                 </div>
              </div>
              <div class="info-section" v-if="client.conjoint">
                 <h3>Conjoint</h3>
                 <div class="info-details">
                    <p>Né(e) le {{ formatDate(client.conjoint.date_naissance) }}</p>
                    <p>Régime {{ titre('Regime', client.conjoint.regime_id) }}</p>
                 </div>
              </div>
              <div class="info-section" v-if="client.enfants && client.enfants.length">
                 <h3>Enfant{{ client.enfants.length > 1 ? 's' : '' }}</h3>
                 <div class="info-details">
                    <p v-for="(enfant, index) in client.enfants" :key="index"> Enfant {{ index + 1 }} : né(e) le {{ formatDate(enfant.date_naissance) }} </p>
                 </div>
              </div>
              <div class="info-section">
                 <h3>Contact</h3>
                 <div class="info-details">
                    <p>{{ client.email }}</p>
                    <p>{{ client.telephone_1 }}</p>
                    <p> {{ client.adresse }}<br> {{ client.adresse_2 }}<br> {{ client.cp }} {{ client.ville }} </p>
                 </div>
              </div>
           </div>
        </div>
        <!-- Edit Panel --> 
        <div class="edit-panel" :class="{ 'edit-panel--open': showEditPanel }">
           <div class="edit-panel__content">
              <div class="edit-panel__header">
                 <h2>Modifier vos informations</h2>
                 <button @click="showEditPanel = false" class="btn-close"> <i class="fas fa-times"></i> </button> 
              </div>
              <!-- Form content here --> 
              <form @submit.prevent="saveChanges" class="edit-form">
                 <!-- Personal Info --> 
                 <div class="form-section">
                    <h3>Informations personnelles</h3>
                    <div class="form-group">
                       <label>Civilité</label> 
                       <select v-model="client.civilite">
                          <option value="Mr">Monsieur</option>
                          <option value="Mme">Madame</option>
                       </select>
                    </div>
                    <div class="form-group"> <label>Nom</label> <input type="text" v-model="client.nom"> </div>
                    <div class="form-group"> <label>Date de naissance</label> <input type="date" v-model="client.date_naissance"> </div>
                 </div>
                 <!-- Contact Info --> 
                 <div class="form-section">
                    <h3>Contact</h3>
                    <div class="form-group"> <label>Email</label> <input type="email" v-model="client.email"> </div>
                    <div class="form-group"> <label>Téléphone</label> <input type="tel" v-model="client.telephone_1"> </div>
                 </div>
                 <!-- Address --> 
                 <div class="form-section">
                    <h3>Adresse</h3>
                    <div class="form-group"> <label>Adresse</label> <input type="text" v-model="client.adresse"> </div>
                    <div class="form-group"> <label>Complément d'adresse</label> <input type="text" v-model="client.adresse_2"> </div>
                    <div class="form-row">
                       <div class="form-group"> <label>Code postal</label> <input type="text" v-model="client.cp"> </div>
                       <div class="form-group"> <label>Ville</label> <input type="text" v-model="client.ville"> </div>
                    </div>
                 </div>
                 <div class="form-actions"> <button type="button" @click="showEditPanel = false" class="btn-secondary">Annuler</button> <button type="submit" class="btn-primary">Enregistrer</button> </div>
              </form>
           </div>
        </div>
        <!-- Filters with stars --> 
        <div class="filters-container">
           <div class="search-box"> <input type="text" placeholder="Rechercher une offre..." v-model="searchQuery" class="search-input" /> </div>
           <div class="rating-filters">
              <div v-for="(key, i) in Object.keys(filters)" :key="i" class="rating-filter">
                 <span class="rating-label">{{ key }}</span> 
                 <b-form-rating no-border size="lg" show-clear v-model="filters[key]" variant="warning" @change="search()"></b-form-rating>
              </div>
           </div>
        </div>
        <!-- Offers Grid --> 
        <div class="offers-grid">
           <div v-for="offer in offres" :key="offer.id" class="offer-card" :class="{ 'offer-card--recommended': offer.id % 3 }" >
              <div class="offer-card__header">
                 <h3>{{ offer.name }}</h3>
                 <span class="offer-card__price">{{ $api.price(offer.price) }}<small>/mois</small></span> 
              </div>
              <h5>{{ offer.subname }}</h5>
              <div class="offer-card__content mt-3">                
                 <div class="offer-ratings">
                    <div v-for="key in Object.keys(offer.ratings)" :key="key" class="offer-rating">
                       <span>{{ key }}</span> 
                       <b-form-rating no-border size="lg" v-model="offer.ratings[key]" variant="warning" inline readonly></b-form-rating>
                    </div>
                 </div>
                 <ul class="offer-card__features">
                    <li v-for="feature in offer.features" :key="feature"> <i class="fas fa-check"></i> {{ feature }} </li>
                 </ul>
              </div>
              <div class="offer-card__footer"> <button class="btn-primary" :class="{ 'btn-recommended': offer.id % 3}"> {{ offer.id % 3 ? 'Offre recommandée' : 'Choisir cette offre' }} </button> </div>
           </div>
        </div>
     </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showEditPanel: false,
      searchQuery: '',
      params: {},
      client: false,
      agent: {
        nom: 'Dupont',
        prenom: 'Jean',
        email: 'jean.dupont@company.fr',
        telephone: '06 12 34 56 78',
        whatsapp: '33612345678'
      },            
      filters:{
        'Dentaire': null,
        'Optique': null,
        'Hospitalisation': null,
        'Médecines douces': null
      },    
      offres: [],  
      offers: [
        {
          id: 1,
          name: 'Essentielle',
          price: 49,
          recommended: false,
          ratings: [
            { type: 'dental', label: 'Dentaire', value: 2 },
            { type: 'optical', label: 'Optique', value: 2 },
            { type: 'hospital', label: 'Hospitalisation', value: 3 },
            { type: 'alternative', label: 'Médecines douces', value: 1 }
          ],
          features: [
            '100% Santé',
            'Consultations',
            'Pharmacie'
          ]
        },
        {
          id: 2,
          name: 'Confort',
          price: 79,
          recommended: true,
          ratings: [
            { type: 'dental', label: 'Dentaire', value: 4 },
            { type: 'optical', label: 'Optique', value: 4 },
            { type: 'hospital', label: 'Hospitalisation', value: 4 },
            { type: 'alternative', label: 'Médecines douces', value: 2 }
          ],
          features: [
            'Tout de l\'Essentielle',
            'Dentaire renforcé',
            'Optique améliorée'
          ]
        },
        {
          id: 3,
          name: 'Premium',
          price: 129,
          recommended: false,
          ratings: [
            { type: 'dental', label: 'Dentaire', value: 5 },
            { type: 'optical', label: 'Optique', value: 5 },
            { type: 'hospital', label: 'Hospitalisation', value: 5 },
            { type: 'alternative', label: 'Médecines douces', value: 5 }
          ],
          features: [
            'Tout du Confort',
            'Médecines douces',
            'Hospitalisation premium'
          ]
        }
      ]
    }
  },
  computed: {    
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString)
      return new Intl.DateTimeFormat('fr-FR').format(date)    
    },
    titre(titre, id) {
      let check = this.params[titre];
      if(check){
        let check2 = check.find(p => p.id === id);
        if(check2){
          return check2.titre
        }
      }
      return ''
    },    
    search(){
      let params = {
        filters: this.filters,
        sort:{key:"price", value:"ASC"},
        page: 1,
        limit: 9
      }
      this.$api.ajax(`/parcour/search?api=${this.$route.params.api}`, params, res => {
        this.offres = res.data
      });
    },
  },  
  mounted(){
    this.$api.ajax(`/parcour/me?api=${this.$route.params.api}`, null, res => {
      if(res.status){
        this.params = res.data.params;
        this.client = res.data.client;
        this.agent = res.data.user;
        this.search();
      }
    })
  }
}
</script>
  
<style>
.app-container {
  min-height: 100vh;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  padding: 2rem;
}

/* Info Summary Styles */
.info-summary {
  background: white;
  border-radius: 16px;
  padding: 2rem;
  margin-bottom: 3rem;
  box-shadow: 0 4px 12px rgba(0,0,0,0.05);
}

.info-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.info-section h3 {
  color: #699ECF;
  margin-bottom: 1rem;
}

.info-details p {
  margin: 0.5rem 0;
  color: #666;
}

.btn-edit {
  padding: 0.5rem 1rem;
  border: 2px solid #699ECF;
  border-radius: 20px;
  background: transparent;
  color: #699ECF;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-edit:hover {
  background: #699ECF;
  color: white;
}

/* Edit Panel Styles */
.edit-panel {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  max-width: 500px;
  height: 100vh;
  background: white;
  box-shadow: -4px 0 12px rgba(0,0,0,0.1);
  transition: right 0.3s ease;
  z-index: 1000;
}

.edit-panel--open {
  right: 0;
}

.edit-panel__content {
  height: 100%;
  overflow-y: auto;
  padding: 2rem;
}

.edit-panel__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.btn-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
}

.edit-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.form-section {
  border-bottom: 1px solid #eee;
  padding-bottom: 1rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #666;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 1rem;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

/* Rating Filters Styles */
.rating-filters {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
}

.rating-filter {
  background: white;
  padding: 1rem;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.rating-label {
  display: block;
  margin-bottom: 0.5rem;
  color: #666;
}

/* Offer Card Styles */
.offer-ratings {
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #eee;
}

.offer-rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.offer-rating span {
  color: #666;
}

.offers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.offer-card {
  background: white;
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0,0,0,0.05);
  transition: transform 0.3s ease;
}

.offer-card:hover {
  transform: translateY(-5px);
}

.offer-card--recommended {
  border: 2px solid #699ECF;
}

.offer-card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.offer-card__price {
  font-size: 1.5rem;
  font-weight: bold;
  color: #699ECF;
}

.offer-card__price small {
  font-size: 0.875rem;
  color: #666;
}

.offer-card__features {
  list-style: none;
  padding: 0;
  margin: 0;
}

.offer-card__features li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  color: #666;
}

.offer-card__features i {
  color: #699ECF;
}

.offer-card__footer {
  margin-top: 2rem;
  text-align: center;
}

.btn-primary {
  padding: 0.8rem 2rem;
  background: #699ECF;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-primary:hover {
  background: #5a8dbf;
}

.btn-secondary {
  padding: 0.8rem 2rem;
  background: transparent;
  color: #666;
  border: 1px solid #ddd;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-secondary:hover {
  background: #f8f9fa;
}

.btn-recommended {
  background: #e74c3c;
}

.btn-recommended:hover {
  background: #c0392b;
}

.search-box {
  margin-bottom: 2rem;
}

.search-input {
  width: 100%;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
}

.search-input:focus {
  outline: none;
  border-color: #699ECF;
  box-shadow: 0 0 0 2px rgba(105, 158, 207, 0.2);
}  
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background: white;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.navbar__logo {
  flex-shrink: 0;
}

.logo {
  height: 40px;
  width: auto;
}

.navbar__agent {
  text-align: right;
}

.agent-info h3 {
  margin: 0 0 0.5rem 0;
  color: #333;
  font-size: 1.1rem;
}

.agent-contact {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.contact-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #666;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-link:hover {
  color: #699ECF;
}

.contact-link i {
  font-size: 1.1rem;
}

.whatsapp {
  color: #25D366;
}

.whatsapp:hover {
  color: #128C7E;
}  
.text-warning {
    color: #ffd703 !important;
}
</style>